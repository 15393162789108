/**
 * Javascript for Ads.
 *
 **/

/**
 * Prepares and sends data from ad tags to Freestar for ad rendering.
 */
// Object from Ajax
const viewPortHeight = Math.max(
  document.documentElement.clientHeight,
  window.innerHeight || 0
);

const isMobileDevice = window.matchMedia(
  "only screen and (max-width: 760px)"
).matches;

// Connatix ad tags:
// - mobile only
// - shown on specific sites and sections
let site = "";
let connatixBodyTag;
let connatixAdTag;
const isConnatixEnabled = () => {
  if (!isMobileDevice) {
    return false;
  }

  if ("undefined" !== typeof hide_outstream_ads && hide_outstream_ads) {
    return false;
  }

  if ("undefined" === typeof ps_ad_object) {
    return false;
  }

  const not_content = ["homepage", "page", "search", "topic"];
  const this_content = JSON.parse(ps_ad_object[0].page_targeting).ctype;
  if (not_content.includes(this_content)) {
    return false;
  }

  const domain = window.location.hostname;
  const path = window.location.pathname.split("/")[1];

  let is_nc = false;
  if ("undefined" !== typeof is_nc_section) {
    is_nc = is_nc_section[0];
  }

  if (domain.includes("beautycon") || domain.includes("essnc.")) {
    if (is_nc) {
      site = "naturallycurly";
    } else {
      site = "beautycon";
    }
  } else if (
    (domain.includes("www.essence") ||
      domain.includes("ess.") ||
      domain.includes("essenceweb")) &&
    !path.includes("sokomrkt")
  ) {
    site = "essence";
  }

  const tagsMap = {
    essence: {
      body: `!function(n){if(!window.cnx){window.cnx={},window.cnx.cmd=[];var t=n.createElement('iframe');t.src='javascript:false'; t.display='none',t.onload=function(){var n=t.contentWindow.document,c=n.createElement('script');c.src='//cd.connatix.com/connatix.player.js?cid=f9509d53-804e-427d-a0bc-1204c0a3bcb1',c.setAttribute('async','1'),c.setAttribute('type','text/javascript'),n.body.appendChild(c)},n.body.appendChild(t)}}(document);`,
      ad: `<script async src="https://assets.connatix.com/Elements/e044018b-533a-471e-8e55-e3e6acfac5bd/essence-players.js"></script>`,
    },
    naturallycurly: {
      body: `!function(n){if(!window.cnx){window.cnx={},window.cnx.cmd=[];var t=n.createElement('iframe');t.src='javascript:false'; t.display='none',t.onload=function(){var n=t.contentWindow.document,c=n.createElement('script');c.src='//cd.connatix.com/connatix.player.js?cid=f9509d53-804e-427d-a0bc-1204c0a3bcb1&pid=41ea94c8-e157-4783-af66-e73d54d60471',c.setAttribute('async','1'),c.setAttribute('type','text/javascript'),n.body.appendChild(c)},n.head.appendChild(t)}}(document);`,
      ad: `!function(){let x="xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g,function(x){var e=16*Math.random()|0;return("x"==x?e:3&e|8).toString(16)}),e=document.currentScript||document.querySelector("script.cnxtemp");e.setAttribute("id",x),e.removeAttribute("class"),(new Image()).src = 'https://capi.connatix.com/tr/si?token=4ea21d78-9205-4ec5-a7de-f7f5a5bd3492&cid=f9509d53-804e-427d-a0bc-1204c0a3bcb1', cnx.cmd.push(function(){cnx({playerId:"41ea94c8-e157-4783-af66-e73d54d60471"}).render(x)})}();`,
    },
    beautycon: {
      body: `!function(n){if(!window.cnx){window.cnx={},window.cnx.cmd=[];var t=n.createElement('iframe');t.src='javascript:false'; t.display='none',t.onload=function(){var n=t.contentWindow.document,c=n.createElement('script');c.src='//cd.connatix.com/connatix.player.js?cid=f9509d53-804e-427d-a0bc-1204c0a3bcb1&pid=93c0ae75-a46d-4611-9287-3b42db35fd2d',c.setAttribute('async','1'),c.setAttribute('type','text/javascript'),n.body.appendChild(c)},n.head.appendChild(t)}}(document);`,
      ad: `!function(){let x="xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g,function(x){var e=16*Math.random()|0;return("x"==x?e:3&e|8).toString(16)}),e=document.currentScript||document.querySelector("script.cnxtemp");e.setAttribute("id",x),e.removeAttribute("class"),(new Image()).src = 'https://capi.connatix.com/tr/si?token=4ea21d78-9205-4ec5-a7de-f7f5a5bd3492&cid=f9509d53-804e-427d-a0bc-1204c0a3bcb1', cnx.cmd.push(function(){cnx({playerId:"93c0ae75-a46d-4611-9287-3b42db35fd2d"}).render(x)})}();`,
    },
  };

  if (site) {
    connatixBodyTag = tagsMap[site].body;
    connatixAdTag = tagsMap[site].ad;
    return true;
  }
  return false;
};

let viewPortMultiplier = isMobileDevice ? 0.9 : 0.5;
// Google Optimize Test override
if (typeof window.viewPortMultiplier !== "undefined") {
  viewPortMultiplier = window.viewPortMultiplier;
}

const rootMargin = Math.floor(viewPortMultiplier * viewPortHeight);

// Observer to detect ads within 3 viewport view
const ads_observer = new IntersectionObserver(
  (entries, observer) => {
    let els = [];

    entries.forEach((entry) => {
      if (entry.intersectionRatio > 0) {
        const el = $(entry.target);
        el.addClass("freestar-pushed");
        els.push(el);
        observer.unobserve(entry.target);
      }
    });

    // Connatix outstream ads.
    if (isConnatixEnabled()) {
      // Separate the ad units that will be replaced by Connatix
      let els_outstream = [];
      els.forEach(function (el) {
        let slot_id = el.attr("id");
        if (
          (slot_id.includes("right_rail") &&
            !slot_id.includes("right_rail_sticky")) ||
          slot_id.includes("incontent") ||
          slot_id.includes("infinite_scroll")
        ) {
          els_outstream.push(el);
          els = els.filter((item) => item.attr("id") !== slot_id);
        }
      });

      setupConnatix().then(() => {
        pushToConnatix(els_outstream);
      });
    }

    pushToFreestar(els);
  },
  {
    rootMargin: `0px 0px ${rootMargin}px 0px`,
    threshold: 0,
  }
);

const setupConnatix = () => {
  return new Promise((resolve, reject) => {
    let scriptId = "connatix-script";
    if (document.getElementById(scriptId) !== null) {
      resolve();
      return;
    }

    let cnx_body_script = document.createElement("script");
    cnx_body_script.id = scriptId;
    cnx_body_script.type = "text/javascript";
    cnx_body_script.textContent = connatixBodyTag;
    cnx_body_script.onload = resolve;
    cnx_body_script.onerror = reject;
    document.body.insertBefore(cnx_body_script, document.body.firstChild);
  });
};

const pushToConnatix = (els) => {
  els.forEach(function (el) {
    // Locate the ad slot to be replaced by Connatix
    let slot_id = el.attr("id");
    let slot = document.getElementById(slot_id);

    let rect = slot.getBoundingClientRect();
    let scrollTop = window.scrollY || document.documentElement.scrollTop;
    let current_position = Math.round(rect.top + scrollTop);

    // Find all the Connatix and JW Player slots
    let connatix_elements = [
      ...document.querySelectorAll("div[id^='connatix_']"),
    ];
    let jw_video_elements = [...document.querySelectorAll(".jw-video-box")];
    let video_elements = [...connatix_elements, ...jw_video_elements];

    // Calculate the distance between the current ad slot and neighboring video elements
    let nearest_video = [
      { direction: "above", id: null, distance: Infinity },
      { direction: "below", id: null, distance: Infinity },
    ];

    video_elements.forEach((video_element) => {
      let rect = video_element.getBoundingClientRect();
      let scrollTop = window.scrollY || document.documentElement.scrollTop;
      let video_position = Math.round(rect.top + scrollTop);

      let distance = Math.abs(current_position - video_position);
      let direction = video_position < current_position ? 0 : 1;
      if (distance < nearest_video[direction].distance) {
        nearest_video[direction].distance = distance;
        nearest_video[direction].id = video_element.id;
      }
    });

    // Only inject Connatix video if there's sufficient gap between top-of-page and neighboring video elements
    let top_spacing = 0;
    let cnx_spacing = 1000;
    if (site === "essence") {
      top_spacing = 1000;
      cnx_spacing = 1500; // about every 7th ad slot
    }
    if (site === "beautycon") {
      cnx_spacing = 1200; // about every 5th ad slot
    }

    if (
      nearest_video[0].distance > cnx_spacing &&
      nearest_video[1].distance > cnx_spacing &&
      window.scrollY > top_spacing
    ) {
      let cnx_container = document.getElementById("connatix_" + slot_id);
      if (slot && !cnx_container) {
        let cnx_id = "connatix_" + slot_id;
        let cnx_container = document.createElement("div");
        cnx_container.setAttribute("id", "ess_cnx_container");
        // Swap-in the outstream ad
        slot.after(cnx_container);
        slot.style.display = "none";

        let cnx_unit = document.createElement("div");
        cnx_unit.setAttribute("id", cnx_id);
        cnx_container.appendChild(cnx_unit);

        let cnx_script = document.createElement("script");
        cnx_script.type = "text/javascript";
        if (connatixAdTag.includes("essence-players")) {
          // Use script tag
          cnx_script.src = connatixAdTag.match(/src="([^"]+)"/)[1];
          cnx_script.async = true;
        } else {
          // Use inline script
          cnx_script.setAttribute("class", "cnxtemp");
          cnx_script.textContent = connatixAdTag;
        }
        cnx_container.appendChild(cnx_script);
      }
    } else {
      pushToFreestar(els);
    }
  });
};

let count = 0;
let positions = ".ad-position";

const pushToFreestar = (els) => {
  let els_to_push = [];
  let ad_unit_path =
    ps_ad_object[0]["account_id"] + ps_ad_object[0]["slot_name"];

  els.forEach(function (el) {
    // Tell Freestar which element to use.
    let slot_id = el.attr("id");
    if (
      !isMobileDevice &&
      typeof slot_id === "string" &&
      slot_id.includes("_mobile_")
    ) {
      return;
    }

    // Tell Freestar which type of ad to send.
    let placement = el.attr("data-placement-name");

    let article_key_values = {};
    article_key_values.pos = slot_id;

    if (
      "undefined" !== typeof ps_ad_object &&
      ps_ad_object[0] &&
      ps_ad_object[0].test !== null
    ) {
      article_key_values.test = ps_ad_object[0].test;
    }

    // Only send the tile value if the current ad is not outofpage 1x1.
    if (!el.hasClass("dfp-ads-outofpage")) {
      count++;
      article_key_values.tile = count;
    }

    if (slot_id.includes("leaderboard")) {
      article_key_values.inDapIF = true;
    }

    // Bring the page targeting & article_key_values values into article_targeting together
    // so that overwriting values within objects does not occur.
    let article_targeting = Object.assign(
      article_key_values,
      page_targeting(el)
    );

    els_to_push.push({
      placementName: placement,
      slotId: slot_id,
      targeting: article_targeting,
    });

    // Define the slot for DoubleVerify
    defineDvtagSlot(slot_id, {
      id: ad_unit_path,
      sizes: [],
    });

    // end forEach
  });

  freestar.queue.push(function () {
    // When DoubleVerify is ready...
    onDvtagReady(function () {
      // 1. Get targeting information (must be performed inside onDvtagReady)
      els_to_push.forEach(function (el) {
        getDvtagTargeting(el.slotId);
      });
      // 2. Push the ad slots to Freestar
      freestar.newAdSlots(els_to_push);
    });
  });
};

const page_targeting = (el) => {
  const krux_data =
    typeof Krux !== "undefined" ? { ksg: Krux.segments, kuid: Krux.user } : {};

  try {
    // Use the targeting data of the article the ad belongs to
    // And use the first article for the out-of-page and leaderboard ads
    const page_targeting = el.closest("article").length
      ? el.closest("article").prev().data("page_targeting")
      : $(".pubstack-post-header").first().data("page_targeting");
    return { ...page_targeting, ...krux_data };
  } catch (e) {
    return krux_data;
  }
};

function freestarAdsRequest() {
  this.initTimer = 0;
  this.init = function () {
    $(window).on("ad:request", this, this.freestarInit);
    $(window).on("ad:scroll_update", this, this.freestarPush);
    this.freestarInit();
  };

  this.freestarInit = () => {
    if (typeof freestar !== "undefined") {
      clearTimeout(this.initTimer);
      if (typeof PQ.loadSignals !== "undefined") {
        // Load DV Authentic Direct signals first
        PQ.loadSignals(["abs"], () => {
          freestarInitAction(); // Proceed with initializing Freestar
        });
      }
    } else {
      this.initTimer = setTimeout(() => {
        this.freestarInit();
      }, 500);
    }
  };

  const freestarInitAction = () => {
    freestar.config.targeting = [{ essence_adhesion: page_targeting() }];
    freestar.initCallback = function () {
      $(positions)
        .not(".observed")
        .slice(0, 100)
        .each((index, el) => {
          $(el).addClass("observed");
          ads_observer.observe(el);
        });
    };
  };

  this.freestarPush = function () {
    $(positions)
      .not(".observed")
      .slice(0, 100)
      .each((index, el) => {
        $(el).addClass("observed");
        ads_observer.observe(el);
      });
  };
}

var freestartAdsRequestInstance = new freestarAdsRequest();
freestartAdsRequestInstance.init();
